import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuPromotion } from './interface/menu.interface';
import { PopupConfig, User } from './interface/user.interface';
import { CampaignPopupComponent } from './popup/campaign-popup/campaign-popup.component';
import { ForgotpasswordPopupComponent } from './popup/forgotpassword-popup/forgotpassword-popup.component';
import { PromotionPopupComponent } from './popup/promotion-popup/promotion-popup.component';
import { SigninPopupComponent } from './popup/signin-popup/signin-popup.component';
import { StoreConfirmationPopupComponent } from './popup/store-confirmation-popup/store-confirmation-popup.component';
import { CartService } from './service/cart.service';
import { CommonService } from './service/common.service';
import { FcmessagingService } from './service/fcmessaging.service';
import { MenuService } from './service/menu.service';
import { UserService } from './service/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  title = 'sushiizuapp';
  user: User;
  storeId: number;
  popupconfig: PopupConfig;
  promotions: MenuPromotion[];
  isResetTrue: boolean = false;

  campaign: any;
  campaignData: any;

  private unsubscribe$ = new Subject();

  constructor(
    private userService: UserService,
    private menuService: MenuService,
    private commonService: CommonService,
    private cartService: CartService,
    private router: Router,
    private fcm: FcmessagingService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    sessionStorage.setItem(this.userService.storedKeyAfterRefresh, JSON.stringify(this.user));
  }

  ngOnInit(): void {
    this.userService.initialize();
    this.userService.getSelectedStore();
    this.userService.clearPopupConfigs();
    this.requestNotification();

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;

        if(this.user?.session && this.user?.is_password_reset == '1') {
          this.isResetTrue = true;
        }
      });

      this.userService.selectedStore$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          this.getDefaultStore();
          return;
        }

        this.storeId = data.id;

        console.log('dstore');

        // promotions
        this.menuService.getPromotion(this.storeId).subscribe((response) => {
          if(!response.success) {
            this.menuService.setPromotion([]);
            return;
          }
          this.menuService.setPromotion(response.data);
        });
        // categories
        this.menuService.getCategory(this.storeId).subscribe((response) => {
          if(!response.success) {
            return;
          }
          this.menuService.setCateogory(response.data);
        });
        // bestseller products
        this.menuService.getHomepageProduct('bestseller', this.storeId).subscribe((response) => {
          if(!response.success) {
            if(response.message === 'No products found.') {
              this.menuService.setBestseller([]);
            }
            return;
          }
          this.menuService.setBestseller(response.data);
        });
        // newline products
        this.menuService.getHomepageProduct('newline', this.storeId).subscribe((response) => {
          if(!response.success) {
            if(response.message === 'No products found.') {
              this.menuService.setNewline([]);
            }
            return;
          }
          this.menuService.setNewline(response.data);
        });
      });

      this.userService.popups$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.popupconfig = data;
      });

      this.menuService.promotions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        if(data && data.length > 0) {
          this.promotions = data;
          // console.log('popup removed config')
          // if(this.popupconfig?.promotion == null || this.popupconfig?.promotion == true) {
          //   this.userService.updatePopup({promotion: null});
          // }
        }
      });
    });

    setTimeout(() => {
      this.listenPopupConfig();
    }, 3000);

    this.fcm.listenNotification();
  }

  private getCampaign(data): void {
    this.menuService.getCampaign(data).subscribe((response) => {
      if(response && response.data) {
        this.campaign = response.data[0];
        this.signupCampaignPopup(this.campaignData, this.campaign);
        return;
      }
      this.userService.updatePopup({signup: true});
    });
  }

  private listenPopupConfig(): void {
    this.userService.popups$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      this.popupconfig = data;

      // if(this.router.url == '/login' || this.router.url == '/register' || this.router.url == '/forgot-password') {
      //   console.log('break', this.router.url);
      //   return;
      // }

      console.log('continues ', this.router.url);

      if(!this.popupconfig?.hasOwnProperty('signup')) {
        let user = this.userService.getUser();
        if(user) {
          this.campaignData = {
            session: user.session,
            deviceID: user.deviceID,
            type: 'signup',
            storeID: this.storeId,
            campaign_type: 'signup'
          }
          this.getCampaign(this.campaignData);
        } else {
          this.userService.updatePopup({signup: true});
        }
      }

      if(this.popupconfig?.signup == true && 
        (this.popupconfig?.welcome == null || !this.popupconfig?.hasOwnProperty('welcome')) ) {
        console.log('welcome')

        let user = this.userService.getUser();
        if(user) {
          this.userService.updatePopup({welcome: true});
        } else {
          this.userService.updatePopup({welcome: false});
          this.welcomeSigninPopup();
        }
      }

      //after welcome popup
      if(this.popupconfig?.welcome == true && 
        (this.popupconfig?.store == null || !this.popupconfig?.hasOwnProperty('store')) ) {
        console.log('store')
        this.userService.updatePopup({store: false});
        this.confirmSelectedStore();
      }

      //after welcome & store popup & if promotion exists
      if(this.popupconfig?.welcome == true && this.popupconfig?.store == true && 
        (this.popupconfig?.promotion == null || !this.popupconfig?.hasOwnProperty('promotion')) ) {
        console.log('promotion')
        this.userService.updatePopup({promotion: false});
        if(this.promotions?.length > 0) {
          this.showPromotionPopup(this.promotions);
        } else {
          this.userService.updatePopup({promotion: true});
        }
      }

      //after welcome popup & store popup & promotion popup & if isreset true
      if(this.popupconfig?.welcome == true && this.popupconfig?.store == true && 
        (this.popupconfig?.promotion == true || !this.popupconfig?.hasOwnProperty('promotion')) && 
        (!this.popupconfig?.hasOwnProperty('forgotpassword')) && this.isResetTrue) {
        console.log('pass')
        this.userService.updatePopup({forgotpassword: false});
        this.forgotPasswordPopup();
      }
    });
  }

  private getDefaultStore(): void {
    this.userService.getUserLocationPayload().then((postData) => {
      this.menuService.stores$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if(!data) {
          let list_store
          if (postData['lat'] != null){
            list_store = '1';
          } else {
            list_store = '0';
          }
            
          
          this.menuService.getStore(postData,list_store).subscribe((response) => {
            if(!response.success) {
              return;
            }
            this.menuService.setStore(response.data);
            console.log(response.message);
          });
          return;
        }
        //console.log(data,postData,postData['lat']);
        
        if (postData['lat'] != null){
          this.userService.setSelectedStore(data[0]);
        }
       // this.userService.setSelectedStore(data[0]);
      });
    });
  }

  private signupCampaignPopup(campaignData, campaign): void {
    const dialogRef = this.dialog.open(CampaignPopupComponent, {
      width: '500px',
      data: campaign
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.userService.updatePopup({signup: true});
      if(data) {
        let postData = { ...campaignData, ...{campaign_id: campaign.id}};
        this.menuService.updateCampaign(postData).subscribe((response) => {
          return;
        });
      }
    });
  }

  private welcomeSigninPopup(): void {
    const dialogRef = this.dialog.open(SigninPopupComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.userService.updatePopup({welcome: true});
      if(data) {
        this.router.navigate(['login']);
      }
    });
  }

  private confirmSelectedStore(): void {
    const dialogRef = this.dialog.open(StoreConfirmationPopupComponent, {
      width: '500px', 
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.userService.updatePopup({store: true});
      if(data) {
        const cartItems = this.cartService.cartLength();
        if(cartItems > 0) {
          let dialogResponse = this.commonService.actionDialog('Alert', 'Your shopping cart will be emptied if you change your store selection', 'Continue');
          dialogResponse.subscribe(data => {
            if(data) {
              this.cartService.destroy();
              this.router.navigate(['store']);
              return;
            }
          });
        } else {
          this.router.navigate(['store']);
          return;
        }
      }
    });
  }

  private showPromotionPopup(promoData: MenuPromotion[]): void {
    let dialogRef = this.dialog.open(PromotionPopupComponent, {
      data: {
        storeId: this.storeId,
        promotionList: promoData
      },
      width: '400px'
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.userService.updatePopup({promotion: true});
    });
  }

  private forgotPasswordPopup(): void {
    this.dialog.open(ForgotpasswordPopupComponent, {width: '400px'});
    this.userService.updatePopup({forgotpassword: true})
  }

  private requestNotification(): void {
    this.fcm.requestTokenPermission().then((token) => {
      this.userService.updateUser({ notificationToken: token });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.menuService.destroy();
    this.userService.destroy();
    this.userService.clearPopupConfigs();
  }

  logout(): void {
    this.userService.logout();
    this.router.navigateByUrl('/login');
  }
}
