// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://sushiizu.it-predator.com/app/',
  facebookAppId: '1142854846169249',
  appName: 'Sushi Izu',
  squarePayment: {
    applicationId: 'sandbox-sq0idb-MdqXPiPFH2-Bec5JZwTOlg',
    locationId: 'LMEGYYJWDDQMF'
    // applicationId: 'sandbox-sq0idb-l-jC7SvP-60AuLrnCWxZ6A',
    // locationId: 'LA6XPB222DGYB'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDgdY5F3XUDN-T2n3fokx3rZD9nwQeBwxg",
    authDomain: "sushiizu.firebaseapp.com",
    databaseURL: "https://sushiizu.firebaseio.com",
    projectId: "sushiizu",
    storageBucket: "sushiizu.appspot.com",
    messagingSenderId: "875438391797",
    appId: "1:875438391797:web:9746052dc67b17e075a0bc",
    measurementId: "G-F19QHRZ8YC"
  },
  fcm: {
    vapidKey: "BOzRLZL4j4OLRAiGG1As1Pg6DwGrmlMMyLDox8k-AD8Zlvn0F_Qzo59RUVtrwEDdh8ci5F1mPzMg36b8W2wXms8"
  },
  recaptchaSiteKey: "6LdFOzcaAAAAAKCf4SeWbnQLrJXzdijlZ4Nyxpy0"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
