import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { campaignParam, MenuCategory, MenuProduct, MenuPromotion, PromotionVoucher, Store } from '../interface/menu.interface';
import { ApiResponse } from '../interface/common.interface';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  promotionApi: string = environment.apiBaseUrl + 'promo';
  categoryApi: string = environment.apiBaseUrl + 'category';
  productApi: string = environment.apiBaseUrl + 'product';
  collectVoucherApi: string = environment.apiBaseUrl + 'collectvoucher';
  storeApi: string = environment.apiBaseUrl + 'store';
  store_2023_Api: string = environment.apiBaseUrl + 'store-2023';
  orderdatetimeApi: string = environment.apiBaseUrl + 'ordertime';
  campaignApi: string = environment.apiBaseUrl + 'campaign';

  private promotions: BehaviorSubject<MenuPromotion[]> = new BehaviorSubject(null);
  promotions$: Observable<MenuPromotion[]> = this.promotions.asObservable();

  private categories: BehaviorSubject<MenuCategory[]> = new BehaviorSubject(null);
  categories$: Observable<MenuCategory[]> = this.categories.asObservable();

  private stores: BehaviorSubject<Store[]> = new BehaviorSubject(null);
  stores$: Observable<Store[]> = this.stores.asObservable();

  private bestsellers: BehaviorSubject<MenuProduct[]> = new BehaviorSubject(null);
  bestsellers$: Observable<MenuProduct[]> = this.bestsellers.asObservable();

  private newlines: BehaviorSubject<MenuProduct[]> = new BehaviorSubject(null);
  newlines$: Observable<MenuProduct[]> = this.newlines.asObservable();

  private selectedProduct: BehaviorSubject<MenuProduct> = new BehaviorSubject(null);
  selectedProduct$: Observable<MenuProduct> = this.selectedProduct.asObservable();

  private storeMessage: BehaviorSubject<string> = new BehaviorSubject(null);
  storeMessage$: Observable<string> = this.storeMessage.asObservable();

  private message: BehaviorSubject<string> = new BehaviorSubject(null);
  message$: Observable<string> = this.message.asObservable();


  constructor(
    private http: HttpClient,
    private userService: UserService,
    private commonService: CommonService
  ) { }

  // promotions
  getPromotion(storeId: any): Observable<ApiResponse> {
    const payload = new HttpParams({ fromObject: { deviceID: this.userService.getDeviceId(), session: this.userService.getSession(), storeID: storeId } });
    return this.http.post<ApiResponse>(this.promotionApi, payload);
  }

  setPromotion(promotionList): void {
    this.promotions.next(promotionList);
  }

  collectVoucher(data): Observable<ApiResponse> {
    const payload = new HttpParams({ fromObject: data });
    return this.http.post<ApiResponse>(this.collectVoucherApi, payload);
  }

  updateVoucherStatus(vouchers: PromotionVoucher[]): PromotionVoucher[] {
    vouchers.map((voucher, index) => {
      vouchers[index].status = 'valid';
      if(new Date() > this.commonService.toDate(voucher.dateExpiry)) {
        vouchers[index].status = 'expired';
      }
      if(voucher.dateRedeem) {
        vouchers[index].status = 'utilized';
      }
      if(voucher.is_match_order == 0) {
        vouchers[index].status = 'invalid';
      }
    });
    return vouchers;
  }

  // categories
  getCategory(storeId): Observable<ApiResponse> {
    const payload = new HttpParams({ fromObject: { deviceID: this.userService.getDeviceId(), storeID: storeId } });
    return this.http.post<ApiResponse>(this.categoryApi, payload);
  }

  setCateogory(categoryList): void {
    this.categories.next(categoryList);
  }

  // products
  getHomepageProduct(type, storeId): Observable<ApiResponse> {
    let productData = {
      sort: 'alphaup',
      category: 'ALL CATEGORY',
      deviceID: this.userService.getDeviceId(),
      type: type,
      storeID: storeId
    }
    const payload = new HttpParams({ fromObject: productData });
    return this.http.post<ApiResponse>(this.productApi, payload);
  }

  setBestseller(bestsellerList): void {
    this.bestsellers.next(bestsellerList);
  }

  setNewline(newlineList): void {
    this.newlines.next(newlineList);
  }

  getProduct(productData, storeId): Observable<ApiResponse> {
    let data = { ...productData, storeID: storeId };
    const payload = new HttpParams({ fromObject: data });
    return this.http.post<ApiResponse>(this.productApi, payload);
  }

  setSelectedProduct(product): void {
    this.selectedProduct.next(product);
  }

  // stores
  // getStore(data): Observable<ApiResponse> {
  //   const payload = new HttpParams({ fromObject: data });
  //   return this.http.post<ApiResponse>(this.storeApi, payload);
  // }
  getStore(data, list_store: any): Observable<ApiResponse> {
    
    data = { ...data, ...{list_store: list_store }};
    const payload = new HttpParams({ fromObject: data });
    console.log(data);
    return this.http.post<ApiResponse>(this.store_2023_Api, payload);
  }

  setStore(storeList): void {
    this.stores.next(storeList);
  }
  // setStoreMessage(message): void{
  //   // data = { ...data, ...{type: type, time_zone_abbrev: timezone} };
  //     this.storeMessage.next(message);
  //     return message;
  // }

  storeOrderDateTime(data, type: string): Observable<ApiResponse> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    data = { ...data, ...{type: type, time_zone_abbrev: timezone} };
    const payload = new HttpParams({ fromObject: data });
    return this.http.post<ApiResponse>(this.orderdatetimeApi, payload);
  }

  getCampaign(data: campaignParam): Observable<ApiResponse> {
    let postdata = { ...data};
    const payload = new HttpParams({ fromObject: postdata });
    return this.http.post<ApiResponse>(this.campaignApi, payload);
  }
  updateCampaign(data: campaignParam): Observable<ApiResponse> {
    let postdata = { ...data, ...{type: 'update'}};
    const payload = new HttpParams({ fromObject: postdata });
    return this.http.post<ApiResponse>(this.campaignApi, payload);
  }

  destroy(): void {
    this.promotions.next(null);
    this.stores.next(null);
    this.categories.next(null);
    this.bestsellers.next(null);
    this.newlines.next(null);
    this.selectedProduct.next(null);
  }
}
