import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PopupConfig, User } from '../interface/user.interface';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse, Location } from '../interface/common.interface';
import { of } from 'rxjs';
import { PromotionVoucher, Store } from '../interface/menu.interface';
import { CommonService } from './common.service';
import { Order, OrderSuccess } from '../interface/order.interface';
import { CartService } from './cart.service';
// import { FcmessagingService } from './fcmessaging.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public storedKeyAfterRefresh: string = 'refreshed';

  walletvoucherApi: string = environment.apiBaseUrl + 'walletvoucher';
  profileApi: string = environment.apiBaseUrl + 'profile';
  updateProfileApi: string = environment.apiBaseUrl + 'profileupdate';
  referralApi: string = environment.apiBaseUrl + 'referral';
  changePasswordApi: string = environment.apiBaseUrl + 'changepassword';
  redeemVoucherApi: string = environment.apiBaseUrl + 'redeemvoucher';
  orderhistoryApi: string = environment.apiBaseUrl + 'orderhistory';
  payoptionApi: string = environment.apiBaseUrl + 'payoption-v2';
  submitOrderApi: string = environment.apiBaseUrl + 'order-v2';
  getContentApi: string = environment.apiBaseUrl + 'content';

  private user: BehaviorSubject<User> = new BehaviorSubject(null);
  user$: Observable<User> = this.user.asObservable();

  private selectedStore: BehaviorSubject<Store> = new BehaviorSubject(null);
  selectedStore$: Observable<Store> = this.selectedStore.asObservable();

  private selectedVoucher: BehaviorSubject<PromotionVoucher> = new BehaviorSubject(null);
  selectedVoucher$: Observable<PromotionVoucher> = this.selectedVoucher.asObservable();

  private content: BehaviorSubject<any> = new BehaviorSubject(null);
  content$: Observable<any> = this.content.asObservable();

  private paymentOrder: BehaviorSubject<OrderSuccess> = new BehaviorSubject(null);
  paymentOrder$: Observable<OrderSuccess> = this.paymentOrder.asObservable();

  private popups: BehaviorSubject<PopupConfig> = new BehaviorSubject(null);
  popups$: Observable<PopupConfig> = this.popups.asObservable();

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private cartService: CartService
    // private fcm: FcmessagingService
  ) { }

  // session
  initialize(): void {
    const refreshed = JSON.parse(sessionStorage.getItem(this.storedKeyAfterRefresh));
    sessionStorage.removeItem(this.storedKeyAfterRefresh);

    const pconfig = sessionStorage.getItem('pconfig');
    if(pconfig) {
      let temp = JSON.parse(pconfig);
      this.popups.next(temp);
    }

    if(refreshed) {
      const data: User = refreshed;
      if(data.deviceID == this.getDeviceId()) {
        this.user.next(data);
        return;
      }
    }
    this.user.next({
      deviceID: this.getDeviceId(), 
    });
  }
  destroy(): void {
    this.user.next(null);
    this.selectedStore.next(null);
    this.initialize();
  }
  logout(): void {
    // this.fcm.deleteNotificationToken();
    this.user.next(null);
    this.selectedStore.next(null);
    this.initialize();
  }
  getDeviceId(): string {
    let deviceId = sessionStorage.getItem('deviceId');
    if(!deviceId) {
      deviceId = uuidv4();
      sessionStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
  }
  getSession(): string {
    return this.user.value?.session;
  }
  getAppContent(): Observable<ApiResponse> {
    const user = this.user.value;
    const payload = new HttpParams({ fromObject: { deviceID: user.deviceID, session: user.session } });
    return this.http.post<ApiResponse>(this.getContentApi, payload);
  }
  storeContent(newContent: any): void {
    this.content.next(newContent);
  }

  // user data
  updateUser(newUser: User): void {
    this.user.next({...this.user.value, ...newUser});
  }
  updateUserStatus(loggedin: boolean): void {
    let userStatus = { loggedin: loggedin };
    this.user.next({...this.user.value, ...userStatus});
  }
  getUser(): User {
    const user = this.user.value;
    if(user.session) {
      return user;
    } else {
      return null;
    }
  }

  // selected store
  setSelectedStore(store: Store): void {
    this.selectedStore.next(store);
    sessionStorage.setItem('sushiizu_store', JSON.stringify(store));
  }
  getSelectedStore(): void {
    const selectedStore = JSON.parse(sessionStorage.getItem('sushiizu_store'));
    if(selectedStore) {
      this.selectedStore.next(selectedStore);
    } else {
      this.cartService.destroy();
    }
  }
  getStoreId(): number {
    return this.selectedStore.value ? this.selectedStore.value.id : null;
  }
  
  async getUserLocationPayload() {
    return new Promise(resolve => {
      this.commonService.getLocation().then((location: Location) => {
        const user = this.user.value;
        let postData = {
          session: user.session,
          deviceID: user.deviceID,
        }
        if(location.lat && location.lon) {
          postData['lat'] = location.lat;
          postData['lon'] = location.lon;
        }
        resolve(postData);
      });
    });
  }

  // wallet voucher
  getWalletVoucher(valid: string = 'true', orderArray: any[] = []): Observable<any> {
    const user = this.user.value;
    let storeId: any = this.selectedStore.value.id;
    if(!user.session) {
      return of(null);
    }
    const payload = new HttpParams({ fromObject: { 
        deviceID: user.deviceID, 
        session: user.session, 
        is_valid: valid, 
        order_array: orderArray,
        storeID: storeId
      } 
    });
    return this.http.post<ApiResponse>(this.walletvoucherApi, payload);
  }
  removeVoucher(): void {
    this.selectedVoucher.next(null);
  }
  selectVoucher(voucher: PromotionVoucher): void {
    this.selectedVoucher.next(voucher);
  }
  redeemVoucher(voucher: PromotionVoucher): Observable<ApiResponse> {
    const user = this.user.value;
    const payload = new HttpParams({ fromObject: { deviceID: user.deviceID, session: user.session, voucherCode: voucher.code } });
    return this.http.post<ApiResponse>(this.redeemVoucherApi, payload);
  }
  isVoucherSelected(): boolean {
    if(this.selectedVoucher.value) {
      return true;
    }
    return false;
  }

  // profile
  getProfile(): Observable<ApiResponse> {
    const user = this.user.value;
    const payload = new HttpParams({ fromObject: { deviceID: user.deviceID, session: user.session } });
    return this.http.post<ApiResponse>(this.profileApi, payload);
  }
  updateProfile(profile): Observable<ApiResponse> {
    const user = this.user.value;
    profile = { ...profile, ...{ deviceID: user.deviceID, session: user.session } };
    const payload = new HttpParams({ fromObject: profile });
    return this.http.post<ApiResponse>(this.updateProfileApi, payload);
  }
  changePassword(data): Observable<ApiResponse> {
    const user = this.user.value;
    data = { ...data, ...{ deviceID: user.deviceID, session: user.session } };
    const payload = new HttpParams({ fromObject: data });
    return this.http.post<ApiResponse>(this.changePasswordApi, payload);
  }

  // referral
  sendReferral(friend): Observable<ApiResponse> {
    const user = this.user.value;
    friend = { ...friend, ...{ deviceID: user.deviceID, session: user.session } };
    const payload = new HttpParams({ fromObject: friend });
    return this.http.post<ApiResponse>(this.referralApi, payload);
  }

  // order
  orderHistory(order): Observable<ApiResponse> {
    const user = this.user.value;
    order = { ...order, ...{ deviceID: user.deviceID, session: user.session } };
    const payload = new HttpParams({ fromObject: order });
    return this.http.post<ApiResponse>(this.orderhistoryApi, payload);
  }

  // checkout
  payOption(data): Observable<any> {
    const user = this.user.value;
    if(!user.session) {
      return of(null);
    }
    data = { ...data, ...{ deviceID: user.deviceID, session: user.session } };
    const payload = new HttpParams({ fromObject: data });
    return this.http.post<ApiResponse>(this.payoptionApi, payload);
  }
  submitOrder(data): Observable<any> {
    const user = this.user.value;
    if(!user.session) {
      return of(null);
    }
    data = { ...data, ...{ deviceID: user.deviceID, session: user.session, channel: 'web' } };
    const payload = new HttpParams({ fromObject: data });
    return this.http.post<ApiResponse>(this.submitOrderApi, payload);
  }
  storePaymentOrder(ordered): void {
    this.paymentOrder.next(ordered);
  }

  //new
  getPopupConfigs(): PopupConfig {
    let temp = {};
    const pconfig = sessionStorage.getItem('pconfig');
    if(pconfig) {
      temp = JSON.parse(pconfig);
    }
    return temp;
  }
  storePopupConfigs(value: PopupConfig): void {
    const popupsConfig = JSON.stringify(value);
    sessionStorage.setItem('pconfig', popupsConfig);
  }
  updatePopup(newPopups: PopupConfig): void {
    let popupsConfig = this.getPopupConfigs();
    popupsConfig = { ...popupsConfig, ...newPopups };
    this.storePopupConfigs(popupsConfig);
    this.popups.next(popupsConfig);
  }
  clearPopupConfigs(): void {
    this.storePopupConfigs({});
    this.popups.next({});
  }
  checkExistPopupConfigs(key: string): boolean {
    let exist = false;
    let popupsConfig = this.getPopupConfigs();
    if(popupsConfig) {
      if(popupsConfig[key] && popupsConfig[key] == true){
        exist = true;
      }
    }
    return exist;
  }
}
