import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Store } from 'src/app/interface/menu.interface';
import { UserService } from 'src/app/service/user.service';
import { StorePopupComponent } from '../store-popup/store-popup.component';

@Component({
  selector: 'app-checkout-store-comfirmation-popup',
  templateUrl: './checkout-store-comfirmation-popup.component.html',
  styleUrls: ['./checkout-store-comfirmation-popup.component.css']
})
export class CheckoutStoreComfirmationPopupComponent implements OnInit {

    selectedStore: Store;
    locationpermission: boolean = false;
  
    constructor(
      private userService: UserService,
      public dialog: MatDialog
    ) { 
      this.userService.selectedStore$
      .pipe(take(2))
      .subscribe((data) => {
        if(!data) {
          return;
        }
        this.selectedStore = data;

        
      });
      
  
      let thisfunc = this;
      navigator.permissions && navigator.permissions.query({name: 'geolocation'})
      .then(function(PermissionStatus) {
        if (PermissionStatus.state == 'granted') {
          thisfunc.locationpermission = true;
        } else {
          thisfunc.locationpermission = false;
        }
      });
    }
  
    ngOnInit(): void {
    }

    selectStore(): void {
      const dialogRef = this.dialog.open(StorePopupComponent, {
        width: '800px',
        disableClose: true
      });
    }

    
}
  