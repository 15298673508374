import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartItem } from 'src/app/interface/cart.interface';
import { Store } from 'src/app/interface/menu.interface';
import { User } from '../../interface/user.interface';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-payment-option-popup',
  templateUrl: './payment-option-popup.component.html',
  styleUrls: ['./payment-option-popup.component.css']
})
export class PaymentOptionPopupComponent implements OnInit {

  errorMessage: string;
  paymentOptions: any;
  user: User;
  selectedOption: { amount: string, option: string, point: number, type: string };
  store: Store;
  cart: CartItem[];
  redeemPoint: number = null;
  remainingBalance: number;
  showRedeemInput: boolean = false;
  showRedeemBtn: boolean = true; //show 1st
  pointTresholdError: boolean = false;

  // isSkipPayment: boolean = false;

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<PaymentOptionPopupComponent>
  ) { }

  ngOnInit(): void {
    this.user = this.data.user;
    this.cart = this.data.cart;
    this.store = this.data.store;

    if(this.cart && this.cart.length <=0) {
      this.errorMessage = 'Please add items to your cart.';
      return;
    }

    if(this.data.selectedPaymentOption) {
      this.selectedOption = this.data.selectedPaymentOption;
    }

    let totalString = parseFloat(this.data.total).toFixed(2); //string with 2 decimal points
    const total = { total_value: totalString };

    this.userService.payOption(total).subscribe((response) => {
      if(!response || !response.success) {
        if(!response || response.message.includes('Invalid session')) {
          this.userService.logout();
          this.errorMessage = 'Please login to continue.';
          return;
        }
        this.errorMessage = response.message;
        return;
      }
      this.paymentOptions = response.data[0];
      console.log(this.paymentOptions)
    });
  }

  selectOption(amount: string, option: string, point: number, optionType: string): void {
    this.selectedOption = { amount: amount, option: option, point: point, type: optionType };
    this.onClose();
  }

  onChangePoint(point:number){

    this.redeemPoint = point;
    let maxValue = this.paymentOptions.partialPaymentButton[0].maxPointsToDeduct;
    let temBalance = this.paymentOptions.fullPaymentButton - (this.redeemPoint*this.paymentOptions.partialPaymentButton[0].valuePerPoint);
    
    if(temBalance >= 0){
      this.remainingBalance = temBalance;
    } else {
      this.remainingBalance = 0;
    }
    if(point < 100 || point > maxValue){
      this.pointTresholdError = true;
    } else {
      this.pointTresholdError = false;
    }
  }

  onClose(): void {
    this.dialogRef.close(this.selectedOption);
  }
}
